export default [
  {
    label: "Select",
    key: "selected",
    visible: true,

  },
  {
    label: "Client",
    key: "client_name",
    thClass: "th-bg-2 width_1",
    visible: true,
  },
  {
    label: "Account",
    key: "account",
    thClass: "th-bg-2 noselect",
    visible: true,
  },
  {
    label: "Mobile",
    key: "mobile",
    thClass: "th-bg-2 noselect",
    visible: true,
  },
  {
    label: "Start Date",
    key: "created_at",
    thClass: "th-bg-2 width_2 noselect",
    sortable: true,
    visible: true,
  },
  {
    label: "Calls",
    key: "calls",
    thClass: "th-bg-2 noselect",
    visible: true,
  },
  {
    label: "SMS",
    key: "SMS",
    thClass: "th-bg-2 noselect",
    visible: true,
  },
  {
    label: "Status",
    key: "status",
    thClass: "th-bg-2 noselect text-center",
    visible: true,
  },
  {
    label: "Tracking",
    key: "tracking",
    thClass: "th-bg-2 noselect",
    visible: true,
  },
];
