<template>
  <div>
    <b-modal
      v-model="DataGlobal.Modal.ModalClientTracking"
      :title="DataGlobal.Modal.Title"
      title-tag="h3"
      hide-footer
      size="lg"
      sticky-header="300px"
      autofocus
      disabled="0"
    >
      <b-row>
        <b-col cols="12" sm="4" md="4" lg="4">
          <b-button
            variant="gradient-primary"
            class="btn-icon rounded-circle position-absolute"
            @click="returnClient()"
          >
            <tabler-icon role="button" icon="ChevronLeftIcon"></tabler-icon>
          </b-button>

          <div class="mb-3 mt-3">
            <div class="text-primary font-weight-bold text-center">
              <span>ADVISOR</span>
            </div>
            <div class="text-center border_s">
              <span>{{ DataGlobal.Modal.ClientData.nameAdvisor }}</span>
            </div>
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="4" lg="4">
          <div class="mb-3 mt-3">
            <div class="text-primary font-weight-bold text-center">
              <span>STATUS</span>
            </div>
            <div class="text-center border_s">
              <span
                class="text-primary font-weight-bold"
                v-if="DataGlobal.Modal.ClientData.status == 1"
                >CURRENT</span
              >
              <span
                class="text-warning font-weight-bold"
                v-if="DataGlobal.Modal.ClientData.status == 2"
                >CHECK</span
              >
              <span
                class="text-danger font-weight-bold"
                v-if="DataGlobal.Modal.ClientData.status == 3"
                >URGENT</span
              >
            </div>
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="4" lg="4">
          <div class="mb-3 mt-3">
            <div class="text-primary font-weight-bold text-center">
              <span>CLIENT</span>
            </div>
            <div class="text-center border_s">
              <span>{{ DataGlobal.Modal.ClientName }}</span>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <!--:::::::::INI TABLE VUE:::::::::::-->
          <div style="border-top: 1px solid #0090e7">
            <b-table
              :items="data"
              :fields="fields"
              responsive
              class="mb-3"
              :busy.sync="isBusy"
              sticky-header="350px"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(status)="datax">
                <span
                  v-if="datax.item.status == 1"
                  class="font-weight-bold text-primary"
                >
                  {{ datax.item.description_status }}
                </span>

                <span
                  v-if="datax.item.status == 2"
                  class="font-weight-bold text-warning"
                >
                  {{ datax.item.description_status }}
                </span>

                <span
                  v-if="datax.item.status == 3"
                  class="font-weight-bold"
                  style="color: red"
                >
                  {{ datax.item.description_status }}
                </span>
              </template>

              <template #cell(description)="datax">
                <div class="d-flex w-100">
                  <div class="ml-1" v-if="datax.item.description != null">
                    {{ datax.item.description }}
                  </div>
                  <b-button
                    variant="outline-primary"
                    class="btn-icon rounded-circle ml-2"
                    @click="openModalDescription(datax.item.id)"
                    v-if="datax.item.user_id == 0 && datax.item.status == 3"
                    :id="'btno_' + datax.item.id"
                    size="sm"
                  >
                    <tabler-icon icon="PencilIcon"></tabler-icon>
                  </b-button>
                </div>
              </template>
            </b-table>
          </div>
          <!--:::::::::FIN TABLE VUE:::::::::::-->
        </b-col>
      </b-row>
    </b-modal>

    <!--::::INI modal-performance-swal::::-->
    <modal-performance-swal :DataGlobalSwalProps="DataGlobal" />
    <!--::::FIN modal-performance-swal::::-->
  </div>
</template>
<script>
import performanceService from "@/views/commons/components/advisor-performance/service/performance.service";
import ModalPerformanceSwal from "@/views/commons/components/advisor-performance/components/ModalPerformanceSwal.vue";

export default {
  props: {
    DataGlobalTrackingProps: { type: Object },
  },
  components: {
    ModalPerformanceSwal,
  },
  data() {
    return {
      DataGlobal: {
        Modal: {
          ModalClient: false,
          ModalClientTracking: false,
          ModalClientNote: false,
          ModalSwal: false,
          Title: "",

          ClientData: {
            modul: 0,
            program: 0,
            id_advisor: 0,
            nameAdvisor: "",
            status: null,
            caid: "",

            year: [],
            month: "",
            period: "",
            permission: "",
          },
        },
        idtracking: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      fields: [
        {
          label: "Status",
          key: "status",
        },
        {
          label: "Updated by",
          key: "updated_by",
        },
        {
          label: "Description",
          key: "description",
        },
      ],
      data: [],
      isBusy: false,
    };
  },
  methods: {
    async searchTracking() {
      this.isBusy = true;
      const response = await performanceService.getTrackingSatusPerformance({
        caid: this.DataGlobal.Modal.ClientData.caid,
        idAdvisor: this.DataGlobal.Modal.ClientData.id_advisor,
        month: this.DataGlobal.Modal.ClientData.month,
        year: this.DataGlobal.Modal.ClientData.year,
      });
      this.data = response;
      this.isBusy = false;
    },
    returnClient() {
      this.DataGlobal.Modal.Title = "CLIENTS";
      this.DataGlobal.Modal.ModalClientTracking = false;
    },
    openModalDescription(id) {
      this.DataGlobal.Modal.ModalSwal = true;
      this.DataGlobal.idtracking = id;
    },
  },
  watch: {
    "DataGlobalTrackingProps.Modal.ModalClientTracking": {
      handler() {
        if (this.DataGlobalTrackingProps.Modal.ModalClientTracking == true) {
          this.DataGlobal = this.DataGlobalTrackingProps;
          this.paginate = this.PaginateTrackingProps;
          this.searchTracking();
        }
      },
    },
    "DataGlobal.Modal.ModalClientTracking": {
      //al cerrar modal
      handler(newVal, oldVal) {
        if (newVal == false) {
          this.DataGlobal.Modal.ModalClient = true;
          //this.paginate.perPage = 10;
        }
      },
    },
  },
};
</script>
