<template>
  <div>
    <header-slot></header-slot>

    <b-card no-body class="border-top-primary border-3 border-table-radius">
      <b-col cols="12" class="pb-md-1">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            class="pt-2 text-center text-lg-right"
          >
            <b-button
              variant="info"
              class="w-100 w-lg-75"
              @click="searchAdvisors(null, null)"
              >Go
            </b-button>
          </b-col>

          <b-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            class="pt-2 px-sm-2 text-center"
          >
            <b-form-select
              v-model="year"
              id="year"
              @change="calculationOfDates()"
            >
              <option v-for="(item, index) in years" :key="index" :value="item">
                {{ item }}
              </option>
            </b-form-select>
          </b-col>

          <b-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            class="pt-2 px-sm-2 text-center"
          >
            <b-form-select
              v-model="month"
              id="month"
              @change="calculationOfDates2()"
            >
              <option
                v-for="(item, index) in months"
                :key="index"
                :value="item.x"
                :disabled="item.x == 1 && year == 2021 ? true : false"
              >
                {{ item.y }}
              </option>
            </b-form-select>
          </b-col>

          <b-col
            cols="12"
            sm="12"
            md="3"
            lg="3"
            class="py-2 text-center text-lg-left"
          >
            <div class="pt0_5">
              <span
                >Start: <label>{{ start }}</label></span
              >
              <span class="px-1">/</span>
              <span
                >End: <label>{{ end }}</label></span
              >
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="12"
            md="3"
            lg="2"
            class="py-2 text-right ml-auto"
          >
            <b-input-group class="input-group-merge" v-if="estadoRefrest">
              <b-input-group-prepend is-text>
                <tabler-icon role="button" icon="ClockIcon"></tabler-icon>
              </b-input-group-prepend>

              <b-form-input
                v-model="timeDiscount"
                placeholder="01:25"
                disabled
              />
            </b-input-group>
            <b-button variant="warning" @click="IniTimeRefresh" v-if="viewRep">
              <span class="align-middle">REFRESH </span>
              <feather-icon icon="ClockIcon" class="mr-10" />
            </b-button>
            <div
              class="text-secondary fw-light"
              style="padding-top: 3px; font-size: 12px"
            >
              Last Update: {{ lastDateUpdated }}
            </div>
          </b-col>
        </b-row>
      </b-col>

      <template>
        <b-table
          :items="data"
          :fields="fields"
          :busy="isBusy"
          class="position-relative"
          responsive="xl"
          table-class="text-nowrap"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(advisor_name)="datax">
            {{ datax.item.advisor_name }}
          </template>

          <template #cell(clients)="datax">
            {{ datax.item.clients }}
          </template>

          <template #cell(c_urgent)="datax">
            <b-badge
              role="button"
              variant="danger"
              pill
              @click="
                OpenModelStatusClient(datax.item.id, datax.item.advisor_name, 3)
              "
              class="btn_global"
              >{{ datax.item.c_urgent }}</b-badge
            >
          </template>

          <template #cell(c_check)="datax">
            <b-badge
              role="button"
              variant="warning"
              pill
              @click="
                OpenModelStatusClient(datax.item.id, datax.item.advisor_name, 2)
              "
              class="btn_global"
              >{{ datax.item.c_check }}</b-badge
            >
          </template>

          <template #cell(c_current)="datax">
            <b-badge
              role="button"
              variant="success"
              pill
              @click="
                OpenModelStatusClient(datax.item.id, datax.item.advisor_name, 1)
              "
              class="btn_global"
              >{{ datax.item.c_current }}</b-badge
            >
          </template>

          <template v-slot:custom-foot>
            <b-tr class="td-totals">
              <b-td class="text-center backg_footer">TOTAL:</b-td>
              <b-td class="text-center backg_footer">
                {{ total_clients }}
              </b-td>
              <b-td class="text-center backg_footer"> {{ total_urgent }} </b-td>
              <b-td class="text-center backg_footer"> {{ total_check }} </b-td>
              <b-td class="text-center backg_footer">
                {{ total_current }}
              </b-td>
              <b-td class="text-center backg_footer">
                <span v-if="percent_contact != ''">{{
                  percent_contact + "%"
                }}</span>
              </b-td>
              <b-td class="text-center backg_footer">
                <span v-if="percent_payments != ''">{{
                  percent_payments + "%"
                }}</span></b-td
              >
              <b-td class="text-center backg_footer">
                <span v-if="percent_average != ''">{{
                  percent_average + "%"
                }}</span></b-td
              >
            </b-tr>
          </template>
        </b-table>
      </template>
    </b-card>

    <!--::::INI modal-performance-clients::::-->
    <modal-performance-clients
      :DataGlobalProps="DataGlobal"
      @close="reloadTable"
    />
    <!--::::FIN modal-performance-clients::::-->

    <!--::::INI modal-performance-note::::-->
    <modal-performance-note :DataGlobalNoteProps="DataGlobal" />
    <!--::::FIN modal-performance-note::::-->

    <!--::::INI modal-performance-Tracking::::-->
    <modal-performance-tracking :DataGlobalTrackingProps="DataGlobal" />
    <!--::::FIN modal-performance-Tracking::::-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import performanceService from "@/views/commons/components/advisor-performance/service/performance.service";
import fieldsPerformance from "./data/fields.Performance";

import ModalPerformanceClients from "@/views/commons/components/advisor-performance/components/ModalPerformance.vue";
import ModalPerformanceNote from "@/views/commons/components/advisor-performance/components/ModalPerformanceNote.vue";
import ModalPerformanceTracking from "@/views/commons/components/advisor-performance/components/ModalPerformanceTracking.vue";

import moment from "moment";
export default {
  components: {
    ModalPerformanceClients,
    ModalPerformanceNote,
    ModalPerformanceTracking,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      skin: "appConfig/skin",
      G_STATE_LEADS: "CrmLeadStore/G_STATE_LEADS",
    }),
    routeModule() {
      return this.$route.meta.route;
    },
    isLeadsRoute() {
      return this.$route.path === `/${this.routeModule}/performance/`;
    },
  },
  data() {
    return {
      DataGlobal: {
        Modal: {
          ModalClient: false,
          ModalClientTracking: false,
          ModalClientNote: false,
          ModalSwal: false,
          Title: "",

          ClientData: {
            modul: 0,
            program: 0,
            id_advisor: 0,
            nameAdvisor: "",
            status: null,
            caid: "",

            year: [],
            month: "",
            period: "",
            permission: "",
          },
        },
      },
      isBusy: false,
      isOnlyLead: false,
      isAddNewUserSidebarActive: false,
      preloading: true,

      isLoading: false,
      keyCreateList: 0,
      filterDataLoaded: false,

      //INI TABLE N1
      fields: fieldsPerformance,
      lastDateUpdated: null,
      listData: [],

      //TimeButonRefresh
      timeSeconds: 150,
      timeDiscount: 0,
      estadoRefrest: false,

      //moment
      years: [],
      year: parseInt(moment().format("YYYY")),
      month: "",
      months: [],
      name_months: [
        0,
        "January",
        "Febrary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      data: null,
      start: "",
      end: "",

      //footerTable
      total_clients: "",
      total_urgent: "",
      total_check: "",
      total_current: "",
      percent_contact: "",
      percent_payments: "",
      percent_average: "",

      status: "",
      period: "",
      c_order: true,
      u_order: true,
      p_order: true,
      a_order: true,
      current_month: moment().format("M"),
      current_year: moment().format("YYYY"),
      viewRep: false,
      permission: 0,
    };
  },
  methods: {
    reloadTable() {
      this.searchAdvisors(null, null);
    },
    refreshReport() {
      var month;
      var year;
      if (moment().format("D") >= 6) {
        month = moment().format("M");
        year = moment().format("YYYY");
      } else {
        month =
          Number(moment().format("M")) - 1 == 0
            ? 12
            : Number(moment().format("M")) - 1;
        year =
          month == 12
            ? Number(moment().format("YYYY")) - 1
            : moment().format("YYYY");
      }
      this.refresh = false;
      axios
        .post("/api/refresh-report-advisor-performance-ce", {
          year: parseInt(year),
          month: parseInt(month),
          program: this.program,
        })
        .then((response) => {
          if (response.status == 200) {
            this.refresh = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async IniTimeRefresh() {
      this.estadoRefrest = true;
      this.viewRep = false;
      await performanceService.getRefreshReportPerformance({
        year: parseInt(this.year),
        month: parseInt(this.month),
        program: this.DataGlobal.Modal.ClientData.program,
        user_id: this.currentUser.user_id,
      });
      this.runTimer();
    },
    async runTimer() {
      const performanceCounterParsed = JSON.parse(
        // Get the timer from local storage
        localStorage.getItem("getPerformanceCounter")
      );
      const timeNow = new Date().getTime();

      if (performanceCounterParsed.timerIsRunning) {
        /*
          If the timer was launched before then
          get the time when the timer was launched 
          and set it with the new vaue
        */
        const timeLaunched = performanceCounterParsed.timeLaunched;
        const timeElapsed = Math.floor((timeNow - timeLaunched) / 1000);
        this.timeSeconds = timeElapsed >= 150 ? 1 : 150 - timeElapsed;
      } else {
        /*
          Set the default values
          if the timer just get launched 
        */
        this.timeSeconds = 150;
        localStorage.setItem(
          "getPerformanceCounter",
          JSON.stringify({
            timeLaunched: timeNow,
            timerIsRunning: true,
          })
        );
      }

      var timex = setInterval(async () => {
        this.timeSeconds--;
        var date = new Date(0);
        date.setSeconds(this.timeSeconds);
        this.timeDiscount = date.toISOString().substr(11, 8);

        if (this.timeSeconds == 0) {
          clearInterval(timex);
          this.timeSeconds = 150;
          this.estadoRefrest = false;
          this.viewRep = true;

          localStorage.setItem(
            /* 
              Set timer to zero once the timer is done
              and set the timerIsRunning to false
            */
            "getPerformanceCounter",
            JSON.stringify({
              timeLaunched: null,
              timerIsRunning: false,
            })
          );

          await this.getLastUpdate();
        }
      }, 900);
    },
    async searchAdvisors(dato1, dato2) {
      this.isBusy = true;
      const response = await performanceService.getPerformance({
        year: this.year,
        month: this.month,
        orderby: 16,
        orden: "desc",
        program: this.DataGlobal.Modal.ClientData.program,
      });
      this.isBusy = false;
      if (response.length) {
        this.data = response;
        this.total_clients = response[0].total_clients;
        this.total_current = response[0].total_current;
        this.total_check = response[0].total_check;
        this.total_urgent = response[0].total_urgent;
        this.percent_contact = response[0].percent_contact;
        this.percent_payments = response[0].percent_payments;
        this.percent_average = response[0].percent_average;
      } else {
        this.data = [];
      }
    },
    calculationOfDates() {
      this.years = [];
      for (var x = 2021; x <= moment().format("YYYY"); x++) {
        this.years.push(x);
      }

      if (this.year == moment().format("YYYY")) {
        if (moment().format("D") >= 6) {
          this.months = [];
          for (var x = 1; x <= moment().format("M"); x++) {
            this.months.push({ x: x, y: this.name_months[x] });
          }
          var last_month = this.months[this.months.length - 1].x;
          this.month = this.months[this.months.length - 1].x;
          if (this.month != last_month) {
            this.month = this.month;
          } else {
            this.month = moment().format("M");
          }

          if (this.estadoRefrest != true) {
            this.viewRep = true;
          }
          this.calculationOfDates2();
        } else {
          var validatemonth =
            Number(moment().format("M") - 1) == 0
              ? 12
              : Number(moment().format("M") - 1);
          for (var x = 1; x <= validatemonth; x++) {
            this.months.push({ x: x, y: this.name_months[x] });
          }
          this.month = this.months[this.months.length - 1].x;

          if (this.estadoRefrest != true) {
            this.viewRep = true;
          }
          this.calculationOfDates2();
        }
      } else {
        this.months = [];
        for (var x = 1; x <= 12; x++) {
          this.months.push({ x: x, y: this.name_months[x] });
        }
        this.viewRep = false;
        this.calculationOfDates2();
      }
      this.DataGlobal.Modal.ClientData.year = this.year;

      //
      this.total_clients = "";
      this.total_current = "";
      this.total_check = "";
      this.total_urgent = "";
      this.percent_contact = "";
      this.percent_payments = "";
      this.percent_average = "";
    },
    calculationOfDates2() {
      if (this.month == 12) {
        this.start = this.month + "/" + "06/" + this.year;
        var m = "01";
        var y = Number(this.year) + 1;
        this.end = m + "/" + "05/" + y;
      } else {
        var m = this.month < 10 ? "0" + this.month : this.month;
        this.start = m + "/" + "06/" + this.year;
        var m2 = Number(m) + 1 < 10 ? "0" + (Number(m) + 1) : Number(m) + 1;
        this.end = m2 + "/" + "05/" + this.year;
      }
      this.DataGlobal.Modal.ClientData.month = this.month;
    },
    OpenModelStatusClient(id, nameAdvisor, status) {
      this.DataGlobal.Modal.Title = "CLIENTS";
      this.DataGlobal.Modal.ModalClient = true;
      this.DataGlobal.Modal.ClientData.nameAdvisor = nameAdvisor;
      this.DataGlobal.Modal.ClientData.id_advisor = id;
      this.DataGlobal.Modal.ClientData.status = status;

      var t_month = this.months[this.months.length - 1].x;
      if (this.month == t_month) {
        this.DataGlobal.Modal.ClientData.permission = 1;
      } else {
        this.DataGlobal.Modal.ClientData.permission = 0;
      }
      this.DataGlobal.Modal.ClientData.period = this.month + "/06/" + this.year;
    },
    async getLastUpdate() {
      const response =
        await performanceService.getLastUpdatedAdvisorPerformance();
      this.lastDateUpdated = response;
    },
  },
  async created() {
    this.calculationOfDates();
    //ProgramId
    this.DataGlobal.Modal.ClientData.modul = this.currentUser.modul_id;
    console.log(this.currentUser);
    this.currentUser.arrRoles.map((item) => {
      if (item.module_id == this.currentUser.modul_id) {
        this.DataGlobal.Modal.ClientData.program = item.program_id;
      }
    });

    this.searchAdvisors(null, null);
    await this.getLastUpdate();
  },

  mounted() {
    const performanceCounter = localStorage.getItem("getPerformanceCounter");
    // Check if exists the object in LocalStorage
    if (!!performanceCounter) {
      const performanceCounterParsed = JSON.parse(performanceCounter);
      if (performanceCounterParsed.timerIsRunning) {
        this.estadoRefrest = true;
        this.viewRep = false;
        this.runTimer();
      }
    } else {
      /* 
        Set timer rules in case doesn't exist
        timeLaunched: is the date when the timer was launched expressed in miliseconds
        timerIsRunning: is a boolean to know if the timer is running or not
      */
      const timer = { timeLaunched: null, timerIsRunning: false };
      localStorage.setItem("getPerformanceCounter", JSON.stringify(timer));
    }
  },
};
</script>
<style>
.go_btn {
  background: #fbb216 !important;
  border: solid 1px #fbb216;
}
.btn_global {
  width: 50px;
}
.back_head {
  text-align: center;
}
.backg_footer {
  background: #666666;
  color: #fff;
}
.border_s {
  border-bottom-style: solid;
  border-bottom-color: #0090e7;
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 940px !important;
  }
}
.pt0_5 {
  padding-top: 5px;
}
</style>
