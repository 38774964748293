export default [
    {
        label:"ADVISOR",    
        key:"advisor_name",
        thClass:'back_head noselect', 
        tdClass:'text-center',
        variant:'icon_new'
    },
    {
        label:"CLIENTS",    
        key:"clients",     
        thClass:'back_head noselect', 
        tdClass:'text-center' 
    },
    {
        label:"URGENT",     
        key:"c_urgent",    
        thClass:'back_head noselect', 
        tdClass:'text-center',
        sortable:true 
    },
    {
        label:"CHECK",      
        key:"c_check",     
        thClass:'back_head noselect', 
        tdClass:'text-center',
        sortable:true 
    },
    {
        label:"CURRENT",    
        key:"c_current",   
        thClass:'back_head noselect', 
        tdClass:'text-center',
        sortable:true 
    },
    {
        label:"CONTACT %",  
        key:"contact",     
        thClass:'back_head noselect', 
        tdClass:'text-center',
        sortable:true 
    },
    {
        label:"PAYMENTS %", 
        key:"payments",    
        thClass:'back_head noselect', 
        tdClass:'text-center',
        sortable:true 
    },
    {
        label:"AVERAGE %",  
        key:"average",     
        thClass:'back_head noselect', 
        tdClass:'text-center',
        sortable:true 
    }
]