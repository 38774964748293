import { amgApi } from "@/service/axios";

class PerformanceService {
  //Process
  async getPerformance(params) {
    const data = await amgApi.post(
      "/commons/advisor-performance/customer-report-by-status-performance-ce",
      params
    );
    return data.data;
  }

  async getSearchClientsStatusPerformance(params) {
    const data = await amgApi.post(
      "/commons/advisor-performance/search-clients-status-performance-ce",
      params
    );
    return data.data;
  }

  async getTrackingSatusPerformance(params) {
    const data = await amgApi.post(
      "/commons/advisor-performance/tracking-status-performance-clients-ce",
      params
    );
    return data.data;
  }

  async getUpdateTrackingPerformance(params) {
    const data = await amgApi.post(
      "/commons/advisor-performance/update-tracking-advisor-performance-ce",
      params
    );
    return data;
  }

  async getRefreshReportPerformance(params) {
    const data = await amgApi.post(
      "/commons/advisor-performance/refresh-report-advisor-performance-ce",
      params
    );
    return data;
  }

  async updateStatusPerformanceClients(params) {
    const data = await amgApi.post(
      "/commons/advisor-performance/update-status-performance-clients-ce",
      params
    );
    return data;
  }

  async getLastUpdatedAdvisorPerformance() {
    try {
      const { data } = await amgApi.post("/commons/advisor-performance/get-last-update-advisor-performance-ce");
      return data;
    } catch (err) {
      console.log(err);
    }
  }
}
export default new PerformanceService();
