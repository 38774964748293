<template>
  <b-modal
    v-model="DataGlobal.Modal.ModalSwal"
    hide-header
    hide-footer
    size="sm"
    sticky-header="300px"
  >
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="12" class="text-center">
        <div class="px-2">
          <div class="swal2-header">
            <div
              class="swal2-icon swal2-warning swal2-icon-show text-center"
              style="display: flex"
            >
              <div class="swal2-icon-content">!</div>
            </div>
            <h2 class="titleSwal" id="swal2-title" style="display: block">
              Are you sure?
            </h2>
          </div>

          <div id="swal2-content" class="swal2-html-container d-block">
            You won't be able to revert this!
          </div>

          <b-form-textarea
            v-model="description"
            placeholder=""
            rows="3"
            class="mt-2"
          ></b-form-textarea>

          <div class="mt-2 mb-1">
            <b-row>
              <b-col cols="6">
                <button
                  type="button"
                  class="swal2-confirm swal2-styled btn-primary px-4"
                  @click="updateDescription()"
                >
                  Yes
                </button>
              </b-col>
              <b-col cols="6">
                <button
                  type="button"
                  class="swal2-cancel swal2-styled btn-danger px-3"
                  @click="DataGlobal.Modal.ModalSwal = false"
                >
                  Cancel
                </button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import performanceService from "@/views/commons/components/advisor-performance/service/performance.service";
export default {
  props: {
    DataGlobalSwalProps: {
      type: Object,
    },
  },
  data() {
    return {
      DataGlobal: {
        Modal: {
          ModalClient: false,
          ModalClientTracking: false,
          ModalClientNote: false,
          ModalSwal: false,
        },
        idtracking: "",
      },
      description: "",
    };
  },
  methods: {
    async updateDescription() {
      const response = await performanceService.getUpdateTrackingPerformance({
        description: this.description,
        id: this.DataGlobal.idtracking,
      });
      if (response.status == 200) {
        this.$swal.fire({
          icon: "success",
          title: "OPERATION SUCCESSFULLY",
        });
        this.$parent.searchTracking();
        this.DataGlobal.Modal.ModalSwal = false;
      }
    },
  },
  watch: {
    "DataGlobalSwalProps.Modal.ModalSwal": {
      handler() {
        if (this.DataGlobalSwalProps.Modal.ModalSwal == true) {
          this.description = "";
          this.DataGlobal = this.DataGlobalSwalProps;
        }
      },
    },
  },
};
</script>
<style scoped>
.titleSwal {
  font-size: 1.5rem;
  font-weight: inherit;
  color: #5e5873;
}
</style>
