<template>
  <b-modal
    ref="modal-change-status-performance"
    hide-header
    hide-footer
    size="sm"
    sticky-header="300px"
    @hidden="$emit('close')"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" class="text-center">
          <div class="px-2">
            <div class="swal2-header">
              <div
                class="swal2-icon swal2-warning swal2-icon-show text-center"
                style="display: flex"
              >
                <div class="swal2-icon-content">!</div>
              </div>
              <h2 id="swal2-title" class="titleSwal" style="display: block">
                Are you sure?
              </h2>
            </div>

            <div id="swal2-content" class="swal2-html-container d-block">
              You are about to change status to {{ newStatus }} to
              {{ numberOfDataClients }}
            </div>
            <ValidationProvider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-textarea
                :state="errors[0] ? false : null"
                v-model="description"
                placeholder=""
                rows="3"
                class="mt-2"
              />
            </ValidationProvider>
            <div class="mt-2 mb-1">
              <b-row>
                <b-col cols="6">
                  <button
                    type="button"
                    class="swal2-confirm swal2-styled btn-primary px-4"
                    @click="updateDescription()"
                  >
                    Yes
                  </button>
                </b-col>
                <b-col cols="6">
                  <button
                    type="button"
                    class="swal2-cancel swal2-styled btn-danger px-3"
                    @click="closeModal()"
                  >
                    Cancel
                  </button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import performanceService from "@/views/commons/components/advisor-performance/service/performance.service";
import modalMixin from "@/mixins/modal";
import { mapGetters } from "vuex";
export default {
  mixins: [modalMixin],
  props: {
    DataClients: {
      type: Array,
    },
    currentStatus: {
      type: Number,
    },
  },
  data() {
    return {
      localDataClients: this.DataClients,
      DataGlobal: {
        Modal: {
          ModalClient: false,
          ModalClientTracking: false,
          ModalClientNote: false,
          ModalSwal: false,
        },
        idtracking: "",
      },
      description: "",
    };
  },
  mounted() {
    this.toggleModal("modal-change-status-performance");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    numberOfDataClients() {
      return this.DataClients.length > 1
        ? this.DataClients.length + " clients."
        : this.DataClients.length + " client.";
    },
    newStatus() {
      switch (this.DataClients[0].status) {
        case 1:
          return "CURRENT";
          break;
        case 2:
          return "CHECK";
          break;
        case 3:
          return "URGENT";
          break;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async updateDescription() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;
      this.localDataClients = this.localDataClients.map((client) => {
        return {
          ...client,
          description: this.description,
          currentStatus: this.currentStatus,
          iduser: this.currentUser.user_id,
        };
      });
      try {
        this.addPreloader();
        const response =
          await performanceService.updateStatusPerformanceClients({
            data_clients: this.localDataClients,
          });
        if (response.status == 200) {
          this.$swal.fire({
            icon: "success",
            title: "OPERATION SUCCESSFULLY",
          });
          this.$emit("close", true);
        }
        this.removePreloader();
      } catch (err) {
        this.removePreloader();
        console.error(err);
      }
    },
  },
};
</script>
  <style scoped>
.titleSwal {
  font-size: 1.5rem;
  font-weight: inherit;
  color: #5e5873;
}
</style>
