<template>
  <b-modal
    v-model="DataGlobal.Modal.ModalClientNote"
    :title="DataGlobal.Modal.Title"
    title-tag="h3"
    hide-footer
    size="lg"
  >
    <b-row>
      <b-col cols="12" sm="12" md="6" lg="6">
        <b-button
          variant="gradient-primary"
          class="btn-icon rounded-circle position-absolute btn_return"
          @click="DataGlobal.Modal.ModalClientNote = false"
        >
          <tabler-icon role="button" icon="ChevronLeftIcon"></tabler-icon>
        </b-button>

        <b-input-group class="mt-5">
          <template #prepend>
            <b-input-group-text variant="gradient-primary"
              >CLIENT</b-input-group-text
            >
          </template>
          <b-form-input
            v-model="DataGlobal.Modal.DataNote.ClientName"
            disabled="disabled"
          ></b-form-input>
        </b-input-group>
      </b-col>

      <b-col cols="12" sm="12" md="6" lg="6">
        <b-input-group class="mt-5">
          <template #prepend>
            <b-input-group-text variant="gradient-primary"
              >CREATED BY</b-input-group-text
            >
          </template>
          <b-form-input
            v-model="detailCreatedBy"
            disabled="disabled"
          ></b-form-input>
        </b-input-group>
      </b-col>

      <b-col cols="12" sm="12" md="12" lg="12">
        <b-form-textarea
          class="mt-2 mb-3"
          placeholder="Enter something..."
          rows="3"
          max-rows="6"
          v-model="DataGlobal.Modal.DataNote.Note"
          disabled="disabled"
        ></b-form-textarea>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import moment from "moment";
export default {
  props: {
    DataGlobalNoteProps: {
      type: Object,
    },
  },
  data() {
    return {
      DataGlobal: {
        Modal: {
          ModalClient: false,
          ModalClientTracking: false,
          ModalClientNote: false,
          ModalSwal: false,
          Title: "",
          DataNote: {
            ClientName: "",
            CreatedBy: "",
            Note: "",
            CreatedAt: "",
          },
        },
      },
    };
  },
  methods: {},
  computed: {
    detailCreatedBy() {
      const { CreatedBy, CreatedAt } = this.DataGlobal.Modal.DataNote;
      return `${CreatedBy} ${moment(CreatedAt).format("MM/DD/YYYY h:mm A")}`;
    },
  },
  watch: {
    "DataGlobalNoteProps.Modal.ModalClientNote": {
      handler() {
        if (this.DataGlobalNoteProps.Modal.ModalClientNote == true) {
          this.DataGlobal = this.DataGlobalNoteProps;
          this.DataGlobal.Modal.Title = "NOTE";
        }
      },
    },
    "DataGlobal.Modal.ModalClientNote": {
      //al cerrar modal
      handler(newVal) {
        //oldVal
        if (this.DataGlobal.Modal.ModalClientNote == false) {
          this.DataGlobal.Modal.Title = "CLIENTS";
        }
      },
    },
  },
};
</script>
<style scoped>
.btn_return {
  top: 5px;
}
</style>