<template>
  <div>
    <b-modal
      v-model="DataGlobal.Modal.ModalClient"
      :title="DataGlobal.Modal.Title"
      title-tag="h3"
      hide-footer
      size="xmd"
      @hide="closeModal()"
    >
      <b-row>
        <b-col cols="12" sm="4" md="4" lg="4">
          <div class="mb-3 mt-1">
            <div class="text-primary font-weight-bold text-center">
              <span>ADVISOR</span>
            </div>
            <div class="text-center border_s">
              <span>{{ DataGlobal.Modal.ClientData.nameAdvisor }}</span>
            </div>
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="4" lg="4">
          <div class="mb-2 mt-1">
            <div class="text-primary font-weight-bold text-center">
              <span>STATUS</span>
            </div>
            <div class="text-center border_s">
              <span class="text-primary font-weight-bold" v-if="isStatusCurrent"
                >CURRENT</span
              >
              <span class="text-warning font-weight-bold" v-if="isStatusCheck"
                >CHECK</span
              >
              <span class="text-danger font-weight-bold" v-if="isStatusUrgent"
                >URGENT</span
              >
            </div>
          </div>
        </b-col>

        <b-col cols="12" sm="4" md="4" lg="4">
          <div class="mb-2 mt-1">
            <div class="text-primary font-weight-bold text-center">
              <span>PERIOD</span>
            </div>
            <div class="text-center border_s">
              <span>{{ DataGlobal.Modal.ClientData.period }}</span>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <!--:::::::::INI TABLE VUE::://::::::::-->
          <div class="bordertop">
            <filter-slot
              :filter="[]"
              :total-rows="totalRows"
              :paginate="paginate"
              :start-page="startPage"
              :to-page="toPage"
              :send-multiple-sms="false"
              :changeColumnsByClient="false"
              :filter-principal="filterPrincipal"
              :no-visible-principal-filter="false"
              @reload="$refs['refClientsList'].refresh()"
            >
              <template #buttons>
                <!-- <b-button
                  class="ml-1"
                  v-if="estateAssign"
                  variant="primary"
                  v-b-tooltip.hover.right="'Assign client'"
                  :disabled="!existClientsWithOutAdvisorAssigned"
                  @click="openModalAsign()"
                >
                  ASSIGN

                  <span v-if="clientsWithOutAdvisor"
                    style="
                      font-size: 20% !important;
                      margin-left: 10px;
                      margin-top: 10px;
                    "
                  >
                    <feather-icon
                      icon
                      :badge="clientsWithOutAdvisor != 0 ? clientsWithOutAdvisor : ''"
                      badge-classes="badge-danger badge-glow "
                    />
                  </span>
                  <feather-icon icon="SendIcon" class="mr-50 ml-1" size="15" />
                </b-button> -->
                <div class="d-flex pl-5">
                  <b-button
                    variant="success"
                    class="ml-2"
                    v-if="!isStatusCurrent"
                    :disabled="!existClientsSelected"
                    @click="changeStatusMultiple(1)"
                    >CURRENT</b-button
                  >
                  <b-button
                    variant="primary"
                    class="ml-2"
                    v-if="!isStatusCheck"
                    @click="changeStatusMultiple(2)"
                    :disabled="!existClientsSelected"
                    >CHECK</b-button
                  >
                  <b-button
                    variant="danger"
                    class="ml-2"
                    v-if="!isStatusUrgent"
                    @click="changeStatusMultiple(3)"
                    :disabled="!existClientsSelected"
                    >URGENT</b-button
                  >
                </div>
              </template>
              <template #table>
                <b-table
                  slot="table"
                  :items="myProvider"
                  :fields="visibleFields"
                  ref="refClientsList"
                  responsive
                  class="mb-3"
                  :busy.sync="isBusy"
                  sticky-header="240px"
                  @sort-changed="sortingChanged"
                  :current-page="paginate.currentPage"
                  :per-page="paginate.perPage"
                  @input="changePerPage"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #head(selected)>
                    <b-form-checkbox
                      v-model="allClientsAreSelected"
                      @input="selectAllClients"
                    />
                  </template>

                  <template #cell(selected)="datax">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="clientsSelected"
                        :value="datax.item"
                        class="mt-1"
                        :disabled="false"
                        :checked="false"
                      />
                    </b-form-group>
                  </template>

                  <template #cell(client_name)="datax">
                    <b-link
                      class="text-primary font-weight-bold"
                      v-if="DataGlobal.Modal.ClientData.program == 2"
                      :to="'/boostcredit/clients/account/' + datax.item.caid"
                      >{{ datax.item.client_name }}
                    </b-link>
                    <b-link
                      class="text-primary font-weight-bold"
                      v-if="DataGlobal.Modal.ClientData.program == 3"
                      :to="'/creditexperts/clients/account/' + datax.item.caid"
                    >
                      {{ datax.item.client_name }}
                    </b-link>
                    <b-link
                      class="text-primary font-weight-bold"
                      v-if="DataGlobal.Modal.ClientData.program == 4"
                      :to="'/debtsolution/clients/account/' + datax.item.caid"
                    >
                      {{ datax.item.client_name }}
                    </b-link>
                  </template>

                  <template #cell(account)="datax">
                    <span class=""> {{ datax.item.account }} </span>
                  </template>

                  <template #cell(mobile)="datax">
                    <span class="d-block" style="width: 45px">
                      {{ datax.item.mobile }}
                    </span>
                  </template>

                  <template #cell(status)="datax">
                    <div
                      class="d-flex justify-content-center"
                      v-if="statusPending.id != datax.item.caid"
                    >
                      <span
                        class="font-weight-bold text-primary"
                        v-if="DataGlobal.Modal.ClientData.status == 1"
                      >
                        {{ datax.item.name_st_performance }}
                      </span>

                      <span
                        class="font-weight-bold text-warning"
                        v-if="DataGlobal.Modal.ClientData.status == 2"
                        >{{ datax.item.name_st_performance }}</span
                      >

                      <span
                        class="font-weight-bold text-danger"
                        v-if="DataGlobal.Modal.ClientData.status == 3"
                        >{{ datax.item.name_st_performance }}</span
                      >
                    </div>
                  </template>

                  <template #cell(created_at)="datax">
                    <span class="">
                      {{ datax.item.created_at | myGlobal }}
                    </span>
                  </template>

                  <template #cell(calls)="datax">
                    <div
                      style="float: left; width: 90px"
                      v-if="datax.item.calls != null"
                    >
                      <span
                        class="font-weight-bold text-primary widthCallDate"
                        v-for="(call, index) in datax.item.calls"
                        :key="index"
                        @click="
                          viewNote(
                            call.content,
                            call.client_name,
                            call.user_name,
                            call.created_at
                          )
                        "
                        role="button"
                      >
                        <span> {{ call.date }} </span>
                        <span
                          v-if="
                            index + 1 > 0 && index + 1 < datax.item.calls.length
                          "
                          class="float-right"
                        >
                          |
                        </span>
                      </span>
                    </div>
                  </template>

                  <template #cell(SMS)="datax">
                    <span class="smsBlock">
                      {{ datax.item.SMS }}
                    </span> </template
                  >\
                  <template #cell(tracking)="datax">
                    <span class="d-block text-center">
                      <tabler-icon
                        role="button"
                        icon="EyeIcon"
                        @click="
                          viewTracking(
                            datax.item.caid,
                            datax.item.client_name,
                            DataGlobal.Modal.ClientData.status
                          )
                        "
                      ></tabler-icon>
                    </span>
                  </template>
                </b-table>
              </template>
            </filter-slot>
          </div>
          <!--:::::::::FIN TABLE VUE:::::::::::-->
        </b-col>
      </b-row>
    </b-modal>

    <!--::::INI modal-performance-Tracking::::
    <modal-performance-tracking 
    :DataGlobalTrackingProps="DataGlobal"
    />-->
    <!--::::FIN modal-performance-Tracking::::-->

    <!--::::INI modal-performance-Note::::-->
    <modal-performance-note :DataGlobalNoteProps="DataGlobal" />
    <!--::::FIN modal-performance-Note::::-->

    <!--::::INI modal-change-status-swal::::-->
    <modal-change-status-swal
      :DataClients="clientsToChangeStatus"
      @close="closeModalChangeStatus"
      :currentStatus="currentStatus"
      v-if="openChangeStatus"
    />
    <!--::::FIN modal-change-status-swal::::-->
  </div>
</template>

<script>
//Service
import FilterSlot from "@/views/commons/components/advisor-performance/slot/FilterSlot.vue";
import performanceService from "@/views/commons/components/advisor-performance/service/performance.service";
import fieldsModalClients from "../data/fields.Performance.Modal.Client";
import filters from "../data/filters.PerformanceClient.data";

//import ModalPerformanceTracking from "@/views/debt-solution/views/performance/components/ModalPerformanceTracking.vue";
import ModalPerformanceNote from "@/views/commons/components/advisor-performance/components/ModalPerformanceNote.vue";
import ModalChangeStatusSwal from "@/views/commons/components/advisor-performance/components/ModalChangeStatusSwal.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    DataGlobalProps: { type: Object },
    lead_name: { type: String },
  },
  components: {
    //ModalPerformanceTracking,
    FilterSlot,
    ModalPerformanceNote,
    ModalChangeStatusSwal,
  },
  data() {
    return {
      clientsSelected: [],
      clientsToChangeStatus: [],
      DataGlobal: {
        Modal: {
          ModalClient: false,
          ModalClientTracking: false,
          ModalClientNote: false,
          ModalSwal: false,
          Title: "",

          ClientData: {
            modul: 0,
            program: 0,
            id_advisor: 0,
            nameAdvisor: "",
            status: null,
            caid: "",

            year: [],
            month: "",
            period: "",
            permission: "",
          },
        },
      },
      countCalls: 1,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      filters: filters,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortDirection: "asc",

      //-----
      isBusy: false,
      currentyear: "",
      currentmonth: "",
      clients: null,
      status_client: "",
      start_page: "",

      perpage: "",
      end_page: "",
      //nextPage: "",
      total: "",
      next_page: "",
      campo: "",

      tracking: false,
      name_client: "",
      fields: fieldsModalClients,
      statusPending: {
        id: 0,
      },
      allClientsAreSelected: false,
      optionStatus: [
        { text: "CURRENT", value: 1 },
        { text: "CHECK", value: 2 },
        { text: "URGENT", value: 3 },
      ],
      newStatus: null,
      clientEdit: null,
      openChangeStatus: false,
      currentStatus: null,
    };
  },
  mounted() {
    if (this.moduleId != 7) {
      this.fields.find((field) => field.key == "status").visible = false;
    } else {
      this.fields.find((field) => field.key == "status").visible = true;
    }
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    optionsFiltered() {
      return this.optionStatus.filter((option) => {
        return option.value != this.DataGlobal.Modal.ClientData.status;
      });
    },
    isStatusCurrent() {
      return this.DataGlobal.Modal.ClientData.status == 1;
    },
    isStatusCheck() {
      return this.DataGlobal.Modal.ClientData.status == 2;
    },
    isStatusUrgent() {
      return this.DataGlobal.Modal.ClientData.status == 3;
    },
    existClientsSelected() {
      return this.clientsSelected.length > 0;
    },
  },
  methods: {
    openSelect(item) {
      if (this.statusPending.id == 0) {
        this.statusPending.id = item.caid;
      } else {
        this.statusPending.id = 0;
      }
    },
    changeStatusMultiple(newStatus) {
      let clientsToChangeStatus = [];
      this.clientsSelected.map((client) => {
        clientsToChangeStatus.push({
          status: newStatus,
          advisor: this.DataGlobal.Modal.ClientData.id_advisor,
          year: parseInt(this.DataGlobal.Modal.ClientData.year),
          month: String(this.DataGlobal.Modal.ClientData.month),
          program: this.DataGlobal.Modal.ClientData.program,
          caid: client.caid,
        });
        this.currentStatus = this.DataGlobal.Modal.ClientData.status;
        this.openChangeStatus = true;
      });
      this.clientsToChangeStatus = clientsToChangeStatus;
    },
    closeModalChangeStatus(changed = false) {
      this.statusPending.id = 0;
      this.newStatus = null;
      this.clientEdit = null;
      this.openChangeStatus = false;
      if (changed) {
        this.allClientsAreSelected = false;
        this.$refs["refClientsList"].refresh();
        this.$emit("close");
      }
    },
    viewTracking(caid, name_client, status) {
      this.status_client = status;
      this.name_client = name_client;
      this.tracking = true;
      this.DataGlobal.Modal.Title = "TRACKING";
      this.DataGlobal.Modal.ModalClient = false;
      this.DataGlobal.Modal.ModalClientTracking = true;
      this.DataGlobal.Modal.ClientName = name_client;
      this.DataGlobal.Modal.ClientData.caid = caid;
    },
    async search() {
      this.isBusy = true;
      this.clients = null;
    },
    viewNote(content, client_name, user_name, created_at) {
      this.DataGlobal.Modal.ModalClientNote = true;
      this.DataGlobal.Modal.DataNote = {
        ClientName: client_name,
        CreatedBy: user_name,
        Note: content,
        CreatedAt: created_at,
      };
    },
    async myProvider(ctx) {
      let sortBy = 4;
      //params
      let params = {
        id_advisor: this.DataGlobal.Modal.ClientData.id_advisor,
        status: this.DataGlobal.Modal.ClientData.status,
        orderby: sortBy,
        order: this.sortDirection,
        campo: this.filterPrincipal.model,
        year: parseInt(this.DataGlobal.Modal.ClientData.year),
        month: String(this.DataGlobal.Modal.ClientData.month),
        perPage: ctx.perPage, //parseInt(this.paginate.perPage),
        page: ctx.currentPage,
      };
      const data = await performanceService.getSearchClientsStatusPerformance(
        params
      );

      this.clients = data.data.map((item) => {
        return {
          client_name: item.client_name,
          account: item.account,
          mobile: item.mobile,
          created_at: item.created_at,
          SMS: item.SMS,
          status: item.status_performance,
          tracking: item.tracking,
          calls: JSON.parse(item.calls),
          caid: item.caid,
          name_st_performance: item.name_st_performance,
        };
      });

      this.startPage = data.from;
      this.totalRows = data.total;
      this.toPage = data.to;
      return this.clients || [];
    },
    sortingChanged(ctx) {
      if (ctx.sortDesc) {
        this.sortDirection = "desc";
      } else {
        this.sortDirection = "asc";
      }
    },
    changePerPage() {
      //this.$store.commit("appConfig/UPDATE_PER_PAGE", this.paginate.perPage);
    },
    selectAllClients() {
      let clients = [];
      this.clients.map((client) => {
        clients.push(client);
      });
      this.clientsSelected = clients;
      if (this.allClientsAreSelected) {
      } else {
        this.clientsSelected = [];
      }
    },
    closeModal() {
      this.clientsSelected = [];
      this.allClientsAreSelected = false;
      this.clientsToChangeStatus = [];
    },
  },
  watch: {
    "DataGlobalProps.Modal.ModalClient": {
      handler() {
        if (this.DataGlobalProps.Modal.ModalClient == true) {
          this.DataGlobal = this.DataGlobalProps;
          this.DataGlobal.Modal.ClientData.period = moment(
            this.DataGlobal.Modal.ClientData.period
          ).format("MMM YYYY");
        }
      },
    },
  },
};
</script>
<style scoped>
.widthCallDate {
  display: block;
  width: 40px;
  float: left;
  text-align: left;
  margin-right: 3px;
  font-size: 12px;
}
.smsBlog {
  display: block;
  width: 30px;
}
.bordertop {
  border-top: 1px solid #0090e7;
}
.b-table-sticky-header {
  margin-bottom: 0rem !important;
}
</style>
